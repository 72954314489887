import React from 'react'

export const DefaultHome = ({waveAnimation, colorTransition, color1, color2, width}) => {
  return (
    <div>
        <div
          className={`d-flex justify-content-center align-items-center wave ${waveAnimation ? "" : ""}`}
          style={{ background: color2, padding:"0px", height:"95vh"}}
        >
          <h1
            className="wave1"
            style={{
              transition: colorTransition ? "ease 1s" : "",
              color: color1,
            }}
          >
            Brighten your business with our digital world
          </h1>
          <p
            className="wave2"
            style={{
              transition: colorTransition ? "ease 1s" : "",
              color: color1,
            }}
          >
            We are the prominent team to elevate your business to reach its
            zenith.
          </p>
        </div>
    </div>
  )
}
