import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import Logo1 from "../../image/Spangles Colour 2.png";
import Logo2 from "../../image/Spangles White 1.png";
import { useLocation } from "react-router-dom";
import "../../Style/Animation.css";

export const Logo = ({ isLink = true, isHome = false }) => {
  const location = useLocation();
  const [logoSrc, setLogoSrc] = useState(Logo1);

  useEffect(() => {
    if (location.pathname === "/") {
      setLogoSrc(Logo2);
    } else if (
      location.pathname === "/Aboutus" ||
      location.pathname === "/Blogs"
    ) {
      setLogoSrc(Logo1);
    } else if (location.pathname.startsWith("/Artificial")) {
      setLogoSrc(Logo1);
    } else {
      setLogoSrc(Logo1);
    }
  }, [location.pathname]);

  useEffect(() => {
    setLogoSrc(Logo2);
  }, []);

  return (
    <Navbar.Brand
      href={isLink && "/"}
      style={{ marginLeft: "6%", position: "relative" }}
    >
      <img src={location.pathname === "/" || location.pathname === "/home" ?  Logo2 :Logo1} alt="Logo" className="logo" />
    </Navbar.Brand>
  );
};
