import React from 'react'
import eve from "../../image/eve.png"
import year from "../../image/year.png"
import star from "../../image/group-stars.png"

export const NewYear = () => {
  return (
    <div className='d-flex flex-column new-year-container'>
        <div className='order-1 position-relative eve-img-container'>
            <img src={eve} className='position-absolute eve-img'/>
            <img src={star} className='position-absolute pulse star-img'  />
            <div className='d-flex align-items-center justify-content-center' style={{zIndex:"50"}}>
                <img src={year} className='position-absolute year-img'/>
            </div>
        </div>
        <div className='order-2  d-flex flex-column align-items-center justify-content-center text-container'>
            <h1
                className="new-year-wave"
            >
                Brighten your business with our digital world
            </h1>
            <p
                className="new-year-wave2"
            >
                We are the prominent team to elevate your business to reach its
                zenith.
            </p>
        </div>
    </div>
  )
}
